<template>
    <Guest>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white uppercase" v-if="showSuccess">
                <i18n-t keypath="register.verify.success.title" />
            </h2>
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white uppercase" v-if="showError">
                <i18n-t keypath="register.verify.failure.title" />
            </h2>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <section class="section section--top">
                    <div class="container container--custom">
                        <div class="row justify-content-center">
                            <div class="col-md-6">
                                <div class="block block--private">
                                    <div v-if="showSuccess">
                                        <h2 class="text-lg text-black font-bold relative">
                                            <i18n-t keypath="register.verify.success.header" />
                                        </h2>
                                        <p class="mt-4 leading-7 text-gray-800">
                                            <i18n-t keypath="register.verify.success.subheader" />
                                        </p>
                                    </div>
                                    <div v-if="showError">
                                        <h2 class="text-lg text-black font-bold relative">
                                            <i18n-t keypath="register.verify.failure.header" />
                                        </h2>
                                        <p class="mt-4 leading-7 text-gray-800">
                                            <i18n-t keypath="register.verify.failure.subheader">
                                                <template v-slot:error>
                                                    {{ errorMessage }}
                                                </template>
                                            </i18n-t>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </Guest>
</template>
<script>
// credit Stefan Knegt: https://gitlab.com/lifebooq/lifebooq-web/-/blob/master/src/views/EmailVerified.vue
import { verifyEmail } from "@/helper/api/RegistrationHelper";
import Guest from "@/wrappers/Guest";
import store from '@/store';

export default {
    name: "EmailVerification",
    components: {
        Guest,
    },
    data () {
        return {
            showSuccess: false,
            showError: false,
            errorMessage: "",
        }
    },
    methods: {
        verify () {
            verifyEmail(this.$route.params.id, this.$route.params.hash, this.$route.query.expires, this.$route.query.signature)
                .then(() => {
                    this.showSuccess = true;
                    
                    setTimeout(() => {
                        this.setUserVerified()
                        this.$router.push( { name: 'dashboard' } )
                    }, 5000);
                })
                .catch(err => {
                    this.errorMessage = err.message;
                    this.showError = true
                    
                    console.error(err)
                })
        },
        setUserVerified() {
            const user = store.getters['getUser'];
            user.verified = 1;
            store.commit('setUser', user);
        },
    },
    created () {
        this.verify()
    }
}
</script>